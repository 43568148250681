// require('soundtouch-js');
import { OLATSPlayer }  from "ola-ts"
var utils = require('audio-buffer-utils');

function clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }
    
class TimeShiftMixer { 

    constructor (audioContext, readyCallback, events) {

        this.audioReady = false; 
        this.duration = 0;

        this.sourceUrl = "";
        this.audioContext = audioContext;
        this.readyCallback = readyCallback;
        this.events = events;

        this.shifters = [false, false] ;
        this.audioCompressed = [false, false];
        this.audioDecompressed = [false, false];

        this.reversed = 0;
        this.maxHertz = 5;
        this.lastTempoTime = 0;
        this.windowSize = 2048;
        this.bufferSize = 8192;
        this.algorithm = "Lanczos";

        this.shouldBePlaying = true;
    
        // console.log("TimeShiftMixer constructed");
        
    }

    translatePosition(pos) {
        if (this.reversed) {
            return this.duration - pos;
        }
        return pos;
    }

    changeTrack(newSrc) {
        // console.log("changeTrack to " + newSrc);
        
        let that = this;
        var buffer;
        if (window.loader.get(newSrc)) {
            buffer = window.loader.get(newSrc).slice(0);
        }
        // console.log(buffer);
        if (!buffer) {
            // console.log("file isn't ready yet... " + newSrc);
            let evt = new Event('preload-file');
            evt.url = newSrc;
            events.dispatchEvent(evt);
            setTimeout(function() {
                that.changeTrack(newSrc);

            }, 500);
            return;
        }
        that.audioCompressed[0] = buffer;

        that.audioContext.decodeAudioData(buffer, function(decodedData) {
            that.audioDecompressed[0] = decodedData;
            that.audioForwardsLoaded();

            that.audioDecompressed[1] = utils.clone(that.audioDecompressed[0]);
            utils.reverse(that.audioDecompressed[1]);
            that.audioBackwardsLoaded();
            // console.log("forwards/backwards decompressed audio loaded."); 
        });
        
        // console.log("forwards compressed audio loaded."); 

    }


    audioForwardsLoaded() {

        if (this.shifters[0]) {
            this.shifters[0].stop();
            this.shifters[0] = false;
        }
        
        this.shifters[0] = new OLATSPlayer(this.audioContext, this.audioDecompressed[0], this.windowSize, this.bufferSize, this.algorithm);
        this.shifters[0].connect(this.audioContext.destination);
        
        this.duration = this.audioDecompressed[0].duration;
        // console.log("new duration is " + this.duration);
        // console.log("shifters[0] loaded");
        if (this.ready()) {
            this.readyCallback();
        }
    }

    audioBackwardsLoaded() {
        if (this.shifters[1]) {
            this.shifters[1].stop();
            this.shifters[1] = false;
        }
        this.shifters[1] = new OLATSPlayer(this.audioContext, this.audioDecompressed[1],  this.windowSize, this.bufferSize, this.algorithm);
        this.shifters[1].connect(this.audioContext.destination);

        // console.log("shifters[1] loaded");
        if (this.ready()) {
            this.readyCallback();
        }
    }

    ready() {
        if (this.shifters[0] && this.shifters[1]) { 
            return true;
        }
        return false;
    }

    play() {
        // console.log("Play: Time to play");
        if (this.ready()) {
            if (this.audioContext.state != "running") {
                this.audioContext.resume();
                // console.log("Play: resumed context from play");
            } 
            this.shifters[this.reversed].play();
            if (!this.shouldBePlaying) {
                this.setCurrentTime(0);
            }
            // console.log("PLAY: " + this.reversed)
        } else {
            // console.log("Play: shifters not yet ready for play.")
        }
        this.shouldBePlaying = true;
    }
    
    pause() {
        // console.log("Pause: Time to pause");
        if (this.ready()) {
            if (this.shifters[this.reversed]) {
                // this.shifters[this.reversed].disconnect(this.audioContext.destination);
                this.shifters[this.reversed].stop();
                // console.log("PAUSED: " + this.reversed);
            } else {
                // console.log("already paused? " + this.reversed);
            }
        }
        this.shouldBePlaying = false;
    }

    reverse() {
        if (this.ready()) {
            let curr = this.getCurrentTime()
            // console.log("reversing at position " + curr)
            this.pause();
            // this.setCurrentTime(curr);
            this.reversed = 1 - this.reversed;
            this.play();
            this.setCurrentTime(curr);
            // console.log("Reverse: done");
        }
    }

    setCurrentTime(time) {
        // console.log("setting time to ",time)
        if (this.ready()) {
            this.shifters[0].position = this.round4(44100 * ( clamp(time,0,this.duration * 0.95)));
            this.shifters[1].position = this.round4(44100 * (clamp((this.duration - time) ,this.duration * 0.05, this.duration)));
        }
    }

    getCurrentTime() {
        if (this.ready()) {
            let pos = ( this.shifters[this.reversed].position / 44100);
            // console.log("getCurrentTime: pos is " + pos + " and duration is " + this.duration)
            if (this.reversed) {
                pos = this.duration - pos;
            }
            return pos;
        }
    }

    getDuration() {
        return this.duration;
    }

    playing() {
        // console.log("shifters[0] playing:" + this.shifters[0].playing + "shifters[1] playing:" + this.shifters[1].playing )
        return (this.shifters[0].playing || this.shifters[1].playing);
    }

    round4(val) {
        return  (Math.floor(val / 4)) * 4;
    }

    shifterEnded() {
        // console.log("track reached end...");
        if(this.shouldBePlaying) {
            let target = this.duration * 0.95;
            if (this.reversed) {
                target = this.duration * 0.05;
            }
            // this.setCurrentTime(target);
            this.play();
            this.setCurrentTime(target);
        }
    }

    setRate(rate) {
        if (!this.ready()) {
            // console.log("not ready");
            return;
        }
        if ((rate < -0.2) && (!this.reversed)) {
            this.reverse();
        } else if ((rate > 0.2) && (this.reversed)) {
            this.reverse();
        }
    
        let newtime = new Date().getTime();
        if (newtime - this.lastTempoTime >= (1000.0 / this.maxHertz)) {
            if ( ( rate > -0.01) && (rate < 0.01) ) { 
                rate = 0.01;
            }
            this.shifters[0].speed = 1.0 / Math.abs(rate);
            this.shifters[1].speed = 1.0 / Math.abs(rate);
            this.lastTempoTime = newtime;
            // console.log("updated tempo to ",Math.abs(rate));
        }
    }

    end (value) {
        this.shouldBePlaying = false;
        this.pause();
    }
}

export default TimeShiftMixer;






