

function ScrubberView() {
    this.makeAccessors();
    this.createDOM();
    this.attachListeners();
    this.onValueChanged = function () {};
    this.onScrubStart = function () {};
    this.onScrubEnd = function () {};
  }
  
  ScrubberView.prototype.makeAccessors = function () {
    var value = undefined;
    var min = 0;
    var max = 1;
  
    this.cleanValue = function (_value=undefined, propagate=true) {

      value = Math.max(min, Math.min(max, _value));
      value = _value;

      this.redraw(value);

      if (propagate) {
          this.onValueChanged(value);
      }
      return value;

    };

  
    this.min = function (_min) {
      if (_min === undefined) return min;
      if (min === _min) return this;
      min = _min;
      this.redraw(value);
      return this;
    };
  
    this.max = function (_max) {
      if (_max === undefined) return max;
      if (max === _max) return this;
      max = _max;
      this.redraw(value);
      return this;
    };

    this.getPageYFromValue = function(val) {
        var rect = this.elt.getBoundingClientRect(); 
        var pare = this.elt.parentNode.getBoundingClientRect(); 
        return ( (rect.height * val) + (rect.top - pare.top));
    }
  };
  
  ScrubberView.prototype.createDOM = function () {
    this.elt = document.createElement('div');
    this.track = document.createElement('div');
    this.thumb = document.createElement('div');
  
    this.elt.className = 'scrubber-vert';
    this.track.className = 'track';
    this.thumb.className = 'thumb';
    this.thumb.style.left = "50%";
  
    this.elt.appendChild(this.track);
    this.elt.appendChild(this.thumb);
    this.redraw(this.value);
  };
  
  ScrubberView.prototype.redraw = function (val) {
    var frac = (val - this.min())/(this.max() - this.min());
    this.thumb.style.top = 100 - (frac*100) + '%';

  };
  
  ScrubberView.prototype.attachListeners = function ()  {
    var self = this;
    var mousedown = false;
    var cachedLeft;
    var cachedWidth;
    var cachedTop;
    var cachedHeight;
    
    var setValueFromPageY = function (pageY, propagate=true) {
        var frac = Math.min(1, Math.max(1 - (pageY - cachedTop)/cachedHeight, 0));
        return self.cleanValue( (1-frac)*self.min() + frac*self.max(),propagate);
      };

    
    var start = function (evt) {
      evt.preventDefault();
      if (mousedown) {
        //   console.log('double start');
          return;
      }
    //   console.log(evt);
      let y = evt.pageY;
      if (y===undefined) {
        y = evt.changedTouches[0].pageY;
      }

      document.addEventListener('mouseup', stop, { passive: false});
      document.addEventListener('touchend', stop, { passive: false});
      
      mousedown = true;
      var rect = self.elt.getBoundingClientRect();
      // NOTE: page[X|Y]Offset and the width and height
      // properties of getBoundingClientRect are not
      // supported in IE8 and below.
      //
      // Scrubber doesn't attempt to support IE<9.
      var xOffset = window.pageXOffset;
      var yOffset = window.pageYOffset;
  
      cachedLeft = rect.left + xOffset;
      cachedWidth = rect.width;
      cachedTop = rect.top + yOffset;
      cachedHeight = rect.height;

    //   console.log("our y is " + y);
      let val = setValueFromPageY(y,true);
      self.onScrubStart(val);
      
      self.thumb.className +=  ' dragging';
    };
  
    var stop = function (evt) {
      document.removeEventListener('mouseup', stop);
      document.removeEventListener('touchend', stop);
      let y = evt.pageY;
      if (y===undefined) {
        y = evt.changedTouches[0].pageY;
      }
      let val = setValueFromPageY(y,false);
      mousedown = false;
      cachedLeft = undefined;
      cachedWidth = undefined;
      cachedTop = undefined;
      cachedHeight = undefined;
      self.thumb.className = 'thumb';

      self.onScrubEnd(val);
      self._value=undefined;
    };
  
    this.elt.addEventListener('mousedown', start, { passive: false});
    this.elt.addEventListener('touchstart', start, { passive: false});
  
    document.addEventListener('mousemove', function (evt) {
      if (!mousedown) return;
      evt.preventDefault();
      setValueFromPageY(evt.pageY);
    }, { passive: false});
  
    document.addEventListener('touchmove', function (evt) {
      if (!mousedown) return;
      evt.preventDefault();
      setValueFromPageY(evt.changedTouches[0].pageY);
    }, { passive: false});
  
    this.elt.addEventListener('mouseup', function (evt) {
      if (!mousedown) return;
      evt.preventDefault();
      setValueFromPageY(evt.pageY);
    }, { passive: false});
  
    this.elt.addEventListener('touchend', function (evt) {
      if (!mousedown) return;
      evt.preventDefault();
      setValueFromPageY(evt.changedTouches[0].pageY);
    }, { passive: false});
  };

  export default ScrubberView;
  