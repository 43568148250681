export class MovingAverageCalculator {
	constructor(factor) {
        this._mean = 0
        this.factor = factor
	}

	update(newValue) {

        const newMean = (this._mean * this.factor) + (newValue* ( 1.0 - this.factor))
		this._mean = newMean
	}

	get mean() {
		this.validate()
		return this._mean
	}

	validate() {
		if (this.count == 0) {
			throw new Error('Mean is undefined')
		}
	}
}
