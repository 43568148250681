import { translate }  from "./translate.js"
import { MovingAverageCalculator } from "./movingAverage.js"
let Controller = require('node-pid-controller');

function clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

class TimePlanner { 

    constructor (mixerAnxious, mixerRelaxed, captioner, events, chart) {
        this.mixerAnxious = mixerAnxious;
        this.mixerRelaxed = mixerRelaxed;
        this.anxiousLowChangePaused = false;
        this.relaxedLowChangePaused = false;
        this.lastValue = 0;
        this.lastTime = 0;
        this.direction = 0;
        this.startValue = -1;
        this.endValue = 0;
        this.durationAnxious = 1;
        this.durationRelaxed = 1;
        this.pidSettings = {
            k_p: 2.00,
            k_i: .075,
            k_d: 0.75,
            i_max: 5.00};
        this.pidAnxious = new Controller(this.pidSettings);
        this.pidRelaxed = new Controller(this.pidSettings);
        // console.log("hello from TimePlanner. type of mixerAnxious is " + typeof(this.mixerAnxious) + " and mixerRelaxed is " + typeof(this.mixerRelaxed));
        this.chart = chart;
        this.interval = false;
        this.maxHertz = 10;
        this.lastTempoTime = 0;
        this.jumpiness = 0.75;
        this.average = new MovingAverageCalculator(this.jumpiness);
        this.captioner = captioner;
        this.captions = [{},{}];
        this.events = events;

        this.events.addEventListener("anxious-track", (val) => {
            // console.log("TimePlanner: caught a anxious-track");
            val = val.track;
            this.changeTracks(val["filename"],val["captions"],undefined,undefined);
        });
        this.events.addEventListener("relaxed-track", (val) => {
            // console.log("TimePlanner: caught a relaxed-track");
            val = val.track;
            this.changeTracks(undefined,undefined,val["filename"],val["captions"]);
        });
    }
    
    changeTracks(anxiousFile=undefined, anxiousCaptions={}, relaxedFile=undefined, relaxedCaptions={}) {
        if (anxiousFile) {
            mixerAnxious.changeTrack(anxiousFile);
            this.captions[0]=anxiousCaptions;
        }
        if (relaxedFile) {
            mixerRelaxed.changeTrack(relaxedFile);
            this.captions[1]=relaxedCaptions;
        }
    }

    start (value) {
        let newtime =  new Date().getTime();
        // console.log("PRESTART: ",value)
        // this.lastValue = value;
        // this.lastTime = newtime;
        this.direction = 0;
        this.startValue = -1;
        this.mixerAnxious.setCurrentTime.bind(this.mixerAnxious, 0.0)();
        this.mixerRelaxed.setCurrentTime.bind(this.mixerRelaxed, 0.0)();
        this.durationAnxious = this.mixerAnxious.getDuration.bind(this.mixerAnxious)();
        this.durationRelaxed = this.mixerRelaxed.getDuration.bind(this.mixerRelaxed)();
        this.pidAnxious.reset();
        this.pidRelaxed.reset();
        // this.mixerAnxious.pause.bind(this.mixerAnxious)();
        // this.mixerRelaxed.pause.bind(this.mixerRelaxed)();
        this.anxiousPaused = true;
        this.relaxedPaused = true;
        // this.output.currentTime = 0;
        this.interval = setInterval(this.intervalHandler.bind(this),1000/this.maxHertz);
        this.average = new MovingAverageCalculator(this.jumpiness);
    }

    intervalHandler() {
        let newtime = new Date().getTime();
        if (newtime - this.lastTempoTime > (1000.0 / this.maxHertz)) {
            this.submit(this.lastValue);
        }
    }
    submit (value) {
        let newtime = new Date().getTime();
        if (newtime - this.lastTempoTime < (1000.0 / this.maxHertz)) {
            return;
        }
    
        this.lastTempoTime = newtime;

        if (this.direction == 0) {
            if (this.startValue == -1 && value != 0 ) {
                this.startValue = value; 
                this.lastValue = value;
                // console.log("START: ",value)
                return;
                // eat first two readings
                // also eat 0 readings since those seem to randomly happen at swipe start??
            } else { 
                if (value < this.lastValue) { 
                    this.direction = 1; 
                    this.endValue = 0.0;  
                    var evt = new Event('track-start-0');
                    evt.startValue = this.startValue;
                    evt.endValue = this.endValue;
                    events.dispatchEvent(evt);
                    this.mixerAnxious.play.bind(this.mixerAnxious)(); 
                    this.mixerAnxious.setCurrentTime(0);
                    this.anxiousPaused = false; 
                    this.captioner.start(this.captions[0]);
                    // console.log("TimePlanner: assigned direction 1. lastValue " + this.lastValue + " value " + value);
                    return;
                }
                if (value > this.lastValue) { 
                    this.direction = -1; 
                    this.endValue = 1.0;  
                    var evt = new Event('track-start-1');
                    evt.startValue = this.startValue;
                    evt.endValue = this.endValue;
                    events.dispatchEvent(evt);
                    this.mixerRelaxed.play.bind(this.mixerRelaxed)(); 
                    this.mixerRelaxed.setCurrentTime(0);
                    this.relaxedPaused = false;
                    this.captioner.start(this.captions[1]);
                    // console.log("TimePlanner: assigned direction -1. lastValue " + this.lastValue + " value " + value);
                    return;
                }

            }

        }
        if ( this.anxiousPaused && this.relaxedPaused ) { 
            // console.log("all paused.");
            return; }
        
        var mixer = this.mixerAnxious;

        var pid = this.pidAnxious;
        var dur = this.durationAnxious;
        var desiredTime = 0;

        if (value !== false) {
            if (this.direction > 0) {
                mixer = this.mixerAnxious;
                pid = this.pidAnxious;
                dur = this.durationAnxious;
                desiredTime = translate(this.startValue - value, this.endValue, this.startValue, 0, dur)
                
            } else {
                mixer = this.mixerRelaxed;
                pid = this.pidRelaxed;
                dur = this.durationRelaxed;
                desiredTime = translate(value, this.startValue, this.endValue, 0, dur)
                
            }

            pid.setTarget(desiredTime);
        }

        var current = mixer.getCurrentTime();
        
        this.average.update(pid.update(current));
        let change = this.average.mean;
        
        change = clamp(change, -5, 5);

        // if ((Math.abs(change) < 0.1) && (mixer.playing())) {
        //     console.log("low change, rewinding: " + change);                
        //     mixer.setCurrentTime(current - (1.0/this.maxHertz))
        // } 
        
        // console.log("at " + newtime + " start value " + this.startValue + " end value " + this.endValue + " new value:" + value + " desiredTime:" + desiredTime + " current time:" + current +  " new speed:",change);
        if (this.chart) {
            this.chart.data.datasets[0].data.push({x: newtime, y: current});
            this.chart.data.datasets[1].data.push({x: newtime, y: desiredTime});
            this.chart.data.datasets[2].data.push({x: newtime, y: change});
        }
        mixer.setRate.bind(mixer,change)(); 
        this.captioner.submit(current);
        

        
        // console.log("valueDiffPercent",valueDiffPercent,"percentPerSecond",percentPerSecond,"new speed:",speed,"duration",dur,"valueDiff",valueDiff, "value",value,"lastValue",this.lastValue,"direction",this.direction);
        
        // Get current position from player.
        // Compute new desired position, and at what rate 
        
        this.lastTime = newtime;
        this.lastValue = value;
        
    }

    end (value) {
        // console.log("END: ",value)
        // wrap up player
        // this.output.pause(); 
        if (this.direction > 0) {
            this.mixerAnxious.pause();
        } else {
            this.mixerRelaxed.pause();
        }
        let progress = 0;
        if (this.direction > 0) {
            progress = translate(this.startValue - value, this.endValue, this.startValue, 0, 1.0) 
        } else {
            progress = translate(value, this.startValue, this.endValue, 0, 1.0)
        }
        let name = 'track-end-' + (this.direction > 0 ? 0 : 1);
        // console.log("sending " + name);
        let evt = new Event(name);
        evt.progress = progress;
        this.events.dispatchEvent(evt);

        this.direction = 0;
        this.startValue = -1;

        this.pidAnxious.reset();
        this.pidRelaxed.reset();
        clearInterval(this.interval);
        this.interval = false;
    }
}

export default TimePlanner;
