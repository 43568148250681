import '../styles/index.scss';

import domready from 'domready';
import TimePlanner from './TimePlanner.js';
import TimeShiftMixer from './TimeShiftMixer.js';
import ScrubberView from './chScrubber.js';
import Chart from 'chart.js';
import Captioner from "./Captioner.js"
import Conductor from "./Conductor.js"
import Cookies from "js-cookie"
var preloader = require('preloader'); 
var LoaderType = require('preloader/lib/loaders/LoaderArrayBuffer');
var analytics = require('universal-ga');

window.tracks = [];
window.currentAnxiousTrack = [0,0,0];
window.currentRelaxedTrack = [0,1,0];
window.currentSection = 0;


domready(function() {   
    var readyRelaxed = false;
    var readyAnxious = false;

    analytics.initialize("UA-62966608-8");

    analytics.pageview(window.location.pathname);
    var debug = false;
    if (Cookies.get("ch-debug")) {
        debug = Cookies.get("ch-debug");
    }
    window.enableDebug = function() {
        Cookies.set("ch-debug",true);
        window.location.href=window.location.href;
    }
    window.disableDebug = function() {
        Cookies.remove("ch-debug");
        window.location.href=window.location.href;
    }

    if (debug) {
        
        var chartel = document.getElementById('myChart');
        chartel.style.display="block";
        var ctx = chartel.getContext('2d');
        document.getElementById('myChart').style.backgroundColor = 'rgba(255,255,255,255)';

        window.chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'line',
        
            options: {
                responsive: true, 
                animation: {
                    duration: 0, // general animation time
                },
                elements: {
                    line: {
                        tension: 0
                    }
                },
                scales: {
                    xAxes: [{
                        type: 'linear', // MANDATORY TO SHOW YOUR POINTS! (THIS IS THE IMPORTANT BIT) 
                        display: true, // mandatory
                        beginAtZero: false,
                        scaleLabel: {
                            display: true
                        },
                    }], 
                    yAxes: [{ // and your y axis customization as you see fit...
                    display: true,
                    beginAtZero: false,
                    scaleLabel: {
                            display: true 
                    }
                }],
            }
            },
            // The data for our dataset
            data: {
                labels: ["time"],
                datasets: [{
                    label: 'current',
                    borderColor: 'rgba(255, 99, 132,255)',
                    data: []
                },{
                    label: 'desired',
                    borderColor: 'rgba(0,0,255,255)',
                    data: []
                }, {
                    label: 'change',
                    borderColor: 'rgba(0,255,0,255)',
                    data: []
                },{
                    label: 'buffer',
                    borderColor: 'rgba(255, 255, 0, 255)',
                    data: []
                }]
            },
        });

        setInterval(function() { window.chart.update({duration: 0});}, 250);
    } else { 
        window.chart = false;
    }
        
    function createAudioContext() {
        if (typeof window.audioContext != "undefined") { return; };

        window.audioContext = new (window.AudioContext || window.webkitAudioContext)({ latencyHint: 'interactive' });
        window.audioContext.resume();
        try {
            startAudioMixers();
            return true;
        } catch(err) {
            window.alert("Oops! We had trouble starting audio: " + err)
        }
        return false;
        
    }
    
    function relaxedReady() {
        readyRelaxed = true;
    }
    
    function anxiousReady() {
        readyAnxious = true;
    }

    // https://stackoverflow.com/questions/15308371/custom-events-model-without-using-dom-events-in-javascript
    var EventTarget = function(options) {
        var target = document.createTextNode(null);
    
        // Pass EventTarget interface calls to DOM EventTarget object
        this.addEventListener = target.addEventListener.bind(target);
        this.removeEventListener = target.removeEventListener.bind(target);
        this.dispatchEvent = target.dispatchEvent.bind(target);
    }
    // Create an instance of your event target
    window.events = new EventTarget();

    function allDone(event) {
        // console.log("all done");
        let evt = new Event('preloader-idle');
        events.dispatchEvent(evt);
    }
    
    window.loader =  preloader({onComplete: allDone
    });

    function filePreloaded(item) {
        // console.log("thingie loaded");
        // console.log(item);

        for (let sec in window.tracks) {
            for (let direction in window.tracks[sec]) {
                for (let track in window.tracks[sec][direction]) {
                    if (window.tracks[sec][direction][track]["filename"] == item) {
                        window.tracks[sec][direction][track]["preloaded"] = true;
                    }
                }
            }
        }
        window.conductor.constructTrackIndicators();
    }
    
    function preloadFile(path,options={}) {
        options["onComplete"]=function() { filePreloaded(path) } ;
        if (path.indexOf(".mp3") > -1 ) {
            window.loader.addFromLoaderType(path,LoaderType,options)
        } else {
            window.loader.add(path,options);
        }
        window.loader.load();
    }

    function preloadEvent(event) {
        let options = {};
        if (event.options) {
            options = event.options;
        }
        preloadFile(event.url);
    }

    function startAudioMixers(events) {
              
        // console.log("startAudioMixers");
        
        window.mixerAnxious = new TimeShiftMixer(window.audioContext,anxiousReady,window.events);
        window.mixerRelaxed = new TimeShiftMixer(window.audioContext,relaxedReady,window.events);
        window.timePlanner = new TimePlanner(mixerAnxious,mixerRelaxed,captioner,window.events,chart);

        // window.mixerInterval = setInterval(function() {window.timePlanner.submit(scrubber.value())}, 200);
    }

    events.addEventListener("open-please-wait",openPleaseWait);
    events.addEventListener("close-please-wait",closePleaseWait);
    
    // events.addEventListener("track-start-0", function(){document.getElementById("ch-player").classList = "row ch-green";});
    // events.addEventListener("track-start-1", function(){document.getElementById("ch-player").classList = "row ch-blue";});

    events.addEventListener("preload-file",preloadEvent);

    //  these don't require audioContext 
    window.captioner = new Captioner("#ch-player-captions",window.events);
    window.conductor = new Conductor(window.events);


    // Make a new scrubber
    window.scrubber = new ScrubberView();
    // Append it to the dom
    document.getElementById('ch-player-contents').appendChild(scrubber.elt);

    // onScrubStart is called whenever a user starts scrubbing
    scrubber.onScrubStart = function(val) {
        timePlanner.start(val);
    };

    // onValueChanged is called whenever the scrubber is moved.
    scrubber.onValueChanged = (value) =>
        timePlanner.submit(value);
    // onScrubEnd is called whenever a user stops scrubbing
    scrubber.onScrubEnd = (value) => 
        timePlanner.end(value);
        
    scrubber.min(0.0).max(1.0);

    function navigateToPlayer(target) {
        window.currentSection = target;
        // console.log("changing to section"+target);
        if (window.tracks.length == 0) {
            openPleaseWait();
            setTimeout(
                function() {
                    navigateToPlayer(target);
                }, 500);
            return;
        }
        closePleaseWait();
        createAudioContext(); // TK TK Later, when direct navigation is possible, init another way
        document.getElementById('ch-player').style.display = 'flex';
        document.getElementById('ch-menu').style.display = 'none';
        // scrubber.redraw();
        window.conductor.changeTracks.bind(window.conductor,[target,0,0],[target,1,0])();
        window.conductor.constructTrackIndicators();
        analytics.pageview(window.location.pathname + "?section=" + target);
    }

    function navigateToMenu(target) {
        document.getElementById('ch-player').style.display = 'none';
        document.getElementById('ch-menu').style.display = 'flex';
        // player.pause();
    }
    
    var body = document.getElementsByTagName('body')[0];

    function openPleaseWait() {
        body.classList.add('waiting');
    }

    function closePleaseWait() {
        body.classList.remove('waiting');
    }

    function openPlayerHelp() {
        body.classList.add('help-open');
    }
    
    function closePlayerHelp() {
        body.classList.remove('help-open');
        Cookies.set("closedPlayerHelp",true)
    }

    function updateStartStop(evt) {
        // console.log(evt.startValue + "+" + evt.endValue);
        let startPos = scrubber.getPageYFromValue(1-evt.startValue);
        let endPos = scrubber.getPageYFromValue(1-evt.endValue);
        let end = document.getElementById("ch-scrubber-indicator-end");
        let start=document.getElementById("ch-scrubber-indicator-start");
        let upwards = document.getElementById("ch-scrubber-fade-upwards");
        let downwards=document.getElementById("ch-scrubber-fade-downwards");
        start.style.display="block";
        start.style.top=(startPos.toString()) + "px";
        end.style.display="block";
        let endheight = end.getBoundingClientRect().height;
        // console.log(end.getBoundingClientRect())
        let mult = endPos > startPos ? -1 : 1 ; 
        if (endPos > startPos) { 
            upwards.style.display="block";
            let upTop = startPos - upwards.getBoundingClientRect().height  ;
            
            upwards.style.top= upTop.toString() + "px"; 
            // console.log(upwards.style.bottom);
        } else {
            downwards.style.display = "block";
            downwards.style.top=startPos.toString() + "px";
        }
        endPos += mult * (endheight / 2);
        end.style.top=(endPos.toString()) + "px";
    }
    function hideStartStop(evt) {

        document.getElementById("ch-scrubber-indicator-start").style.display="none";
        document.getElementById("ch-scrubber-indicator-end").style.display="none";
        document.getElementById("ch-scrubber-fade-upwards").style.display="none";
        document.getElementById("ch-scrubber-fade-downwards").style.display="none";
    }

    window.events.addEventListener("track-start-0",updateStartStop);
    window.events.addEventListener("track-start-1",updateStartStop);
    window.events.addEventListener("track-end-0",hideStartStop);
    window.events.addEventListener("track-end-1",hideStartStop);

    window.loadErrors = 0;
    function packFinished() {
        let returners = []
        for (let sec in window.tracks) {
            for (let direction in window.tracks[sec]) {
                for (let track in window.tracks[sec][direction]) {
                    if (window.tracks[sec][direction][track]["finished"] == true ) {
                        returners.push([ sec, direction, track]);
                    }
                }
            }
        }
        return JSON.stringify(returners);
    }

    function unpackFinished(strang) {
        let finished = JSON.parse(strang);
        for (let b in finished) {
            let a = finished[b]
            window.tracks[a[0]][a[1]][a[2]]["finished"] = true;
        }
    }

    function trackFinished(event) {
        let trak = event.track;
        window.tracks[trak[0]][trak[1]][trak[2]]["finished"] = true;
        Cookies.set("finished",packFinished());
    }

    window.events.addEventListener("track-finished",trackFinished);

    function loadTrackList() {
        // console.log("loading track list...");
        let request = new Request("public/closely-held-audio.json");
        fetch(request)
        .then(response => response.json())
        .then(data => {
            for (let sec in data) {
                for (let direction in data[sec]) {
                    for (let track in data[sec][direction]) {
                        data[sec][direction][track]["filename"] = "public/" + data[sec][direction][track]["filename"];
                        data[sec][direction][track]["preloaded"] = false;
                        data[sec][direction][track]["finished"] = false;
                    }
                }
            }
            window.tracks = data;

            let previouslyFinished = Cookies.get("finished");
            if(previouslyFinished) {
                unpackFinished(previouslyFinished);
            }
        }).catch(error => {
            console.error(error);
            loadErrors++;
            if (loadErrors > 5) {
                window.alert("Sorry! Something bad is happening when we try to load our audio track list. Nerdy details: " + error)
            } else {
                loadTrackList();
            }
          });
    }
    loadTrackList();

    let genre1 = document.getElementsByClassName("ch-genre-1")[0];
    genre1.addEventListener("click",function() {navigateToPlayer(0)});

    let genre2 = document.getElementsByClassName("ch-genre-2")[0];
    genre2.addEventListener("click",function() {navigateToPlayer(1)});

    let genre3 = document.getElementsByClassName("ch-genre-3")[0];
    genre3.addEventListener("click",function() {navigateToPlayer(2)});

    let genre4 = document.getElementsByClassName("ch-genre-4")[0];
    genre4.addEventListener("click",function() {navigateToPlayer(3)});

    let closebutton = document.getElementById("ch-player-close");
    closebutton.addEventListener("click",navigateToMenu);

    let playerhelpopen = document.getElementById("ch-player-help-open");
    playerhelpopen.addEventListener("click",openPlayerHelp);

    let playerhelpclose = document.getElementById("ch-player-help-close");
    playerhelpclose.addEventListener("click",closePlayerHelp);

    let resetbutton = document.getElementById('ch-reset');
    resetbutton.addEventListener("click",function() {
        Cookies.remove("finished");
        Cookies.remove("closedPlayerHelp");
        window.location.href = window.location.href;
    })


    
    if(!Cookies.get('closedPlayerHelp')) {
        openPlayerHelp(); // show on first use
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
            window.alert("Please make sure your device's Silent switch is turned off! \n\niOS thinks these sounds are notification dings, so it'll mute them if your Silent switch is on. \n\nThanks!");
        }
    }

}) 