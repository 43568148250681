
function clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

class Conductor { 

    constructor (events) { 
        // console.log("hello from Conductor");
        this.events = events;
        // this.loader.on("comple", this.fileLoaded, this);
        // this.loader.on("complete", this.allDone, this);
        // this.loader.add("public/sec0-dir0-track0.mp3");
        // this.loader.add("public/sec0-dir1-track0.mp3");
        // this.loader.load();
        this.initialPreload.bind(this)();
        // this.preload("public/sec0-dir0-track0.mp3");
        // this.preload("public/sec0-dir1-track0.mp3");
        this.anxiousIndex = [0,0,0];
        this.relaxedIndex = [0,0,0];
        this.events.addEventListener("track-end-0",this.anxiousEnded.bind(this));
        this.events.addEventListener("track-end-1",this.relaxedEnded.bind(this));
        
        this.events.addEventListener("track-start-0",this.anxiousStarted.bind(this));
        this.events.addEventListener("track-start-1",this.relaxedStarted.bind(this));
        this.relaxedTimeout = undefined;
        this.anxiousTimeout = undefined;
        
    } 

    preload(thing) {
        // console.log("Conductor: preloading " + thing);
        var evt = new Event('preload-file');
        evt.url = thing;
        this.events.dispatchEvent(evt);
    }
    
    relaxedStarted(event) {
        if (this.relaxedTimeout) {
            clearTimeout(this.relaxedTimeout);
        }
        document.getElementById("ch-player-captions").classList.remove("faded");
        document.getElementById("ch-player").classList.add("ch-blue");
        document.getElementById("ch-player").classList.remove("ch-green");
    }

    anxiousStarted(event) {
        if (this.anxiousTimeout) {
            clearTimeout(this.anxiousTimeout);
        }
        document.getElementById("ch-player-captions").classList.remove("faded");
        document.getElementById("ch-player").classList.add("ch-green");
        document.getElementById("ch-player").classList.remove("ch-blue");
    }

    anxiousEnded(event) {
        if (this.anxiousTimeout) {
            clearTimeout(this.anxiousTimeout);
        }
        let progress = event.progress;
        if (progress > 0.5) {
            let evt = new Event('track-finished')
            evt.track = this.anxiousIndex;
            this.events.dispatchEvent(evt);
        }
        var that = this;
        var next = this.getNextTrack(0);            
        document.getElementById("ch-player-captions").classList.add("faded");
        this.anxiousTimeout = setTimeout(function() {
            that.changeTracks(next,undefined);
            document.getElementById("ch-player").classList.remove("ch-green");
        },5000);
    }

    relaxedEnded(event) {
        if (this.relaxedTimeout) {
            clearTimeout(this.relaxedTimeout);
        }
        let progress = event.progress;
        if (progress > 0.5) {
            let evt = new Event('track-finished')
            evt.track = this.relaxedIndex;
            this.events.dispatchEvent(evt);
        }
        var that = this;
        var next = this.getNextTrack(1);
        document.getElementById("ch-player-captions").classList.add("faded");
        this.relaxedTimeout = setTimeout(function() {
            that.changeTracks(undefined,next);
            document.getElementById("ch-player").classList.remove("ch-blue");

        },5000);
    
    }
    changeTracks(anxious=undefined,relaxed=undefined) { 

        if (this.relaxedTimeout) {
            clearTimeout(this.relaxedTimeout);
        }
        if (this.anxiousTimeout) {
            clearTimeout(this.anxiousTimeout);
        }
        if (Object.keys(window.tracks).length == 0) {
            let evt = new Event('open-please-wait');
            this.events.dispatchEvent(evt);
            setTimeout(function() { 
                changeTrack(anxious,relaxed);
            }, 500);
        }
        let evt = new Event('close-please-wait');
        this.events.dispatchEvent(evt);

        if (anxious !== undefined) {
            let track = window.tracks[anxious[0]][anxious[1]][anxious[2]];
            let evt = new Event('anxious-track');
            evt.track = track;
            window.currentAnxiousTrack = track;
            window.currentSection=anxious[0];
            this.anxiousIndex=anxious;
            this.preload(track["filename"]);
            this.events.dispatchEvent(evt);
            let next = this.getNextTrack(0);
            // console.log("next track: " + next);
            this.preload(window.tracks[next[0]][next[1]][next[2]]["filename"]); 
            setTimeout(this.credits.bind(this),250);

        }
        if (relaxed !==undefined) {
            let track = window.tracks[relaxed[0]][relaxed[1]][relaxed[2]];
            let evt = new Event('relaxed-track');
            evt.track = track;
            window.currentRelaxedTrack = track;
            window.currentSection=relaxed[0];
            this.relaxedIndex=relaxed;
            this.preload(track["filename"]);
            this.events.dispatchEvent(evt);
            let next = this.getNextTrack(1);
            // console.log("next track: " + next);
            this.preload(window.tracks[next[0]][next[1]][next[2]]["filename"]); 
            setTimeout(this.credits.bind(this),250);
        }
        this.constructTrackIndicators();
    }

    credits() {
        var creditList = [];
        var credits = "<br/>";

        let relaxed = this.relaxedIndex;
        let anxious = this.anxiousIndex;

        let track = window.tracks[anxious[0]][anxious[1]][anxious[2]];
        if (track["credit html"]) {
            creditList.push('<span class="ch-player-track-credits-green">' + track["credit html"] + '</span>');
        } else if (track["credit"]) {
            creditList.push('<span class="ch-player-track-credits-green"><a href="' + track["credit link"] + '" target="_new">' + track["credit"] + '</a></span>');
        }

        track = window.tracks[relaxed[0]][relaxed[1]][relaxed[2]];
        if (track["credit html"]) {
            creditList.push('<span class="ch-player-track-credits-blue">' + track["credit html"] + '</span>');
        } else if (track["credit"]) {
            creditList.push('<span class="ch-player-track-credits-blue"><a href="' + track["credit link"] + '" target="_new">' + track["credit"] + '</a></span>');
        }

        if (creditList.length) {
            credits = "Audio credits: " + Array.from(creditList).join("; ");
        }
        document.getElementById("ch-player-track-credits").innerHTML = credits;
    }

    initialPreload() {
        if (Object.keys(window.tracks).length > 0) {
            for (var section in window.tracks) {
                this.preload(window.tracks[section][0][0]["filename"]);
                this.preload(window.tracks[section][1][0]["filename"]);
            }
        } else {
            // console.log("deferring initialPreload until window.tracks is available");
            setTimeout(this.initialPreload.bind(this), 500);
        }
    }
    
    getNextTrack(dir) {
        var from = [0, 0, 0];
        var to = [ 0, 0, 1];

        if (dir > 0 ) { 
            from = this.relaxedIndex; 
        } else {
            from = this.anxiousIndex;   
        }

        var trak = parseInt(from[2]);
        var sec = from[0];

        // First, check for subsequent, unplayed tracks
        for (let a = trak + 1; a < Object.keys(window.tracks[sec][dir]).length ; a++ ) {
            if (window.tracks[sec][dir][a]["finished"] != true) {
                return [sec, dir, a];
            }
        } 
        // Then, preceding unplayed tracks
        for (let a = 0; a < trak ; a++ ) {
            if (window.tracks[sec][dir][a]["finished"] != true) {
                return [sec, dir, a];
            }
        } 
        // Then subsequent played tracks

        for (let a = trak + 1; a < Object.keys(window.tracks[sec][dir]).length ; a++ ) {
            return [sec, dir, a];
        } 

        // Then first track
        return [sec, dir, 0];

    }

    constructTrackIndicators(){ 
        if (Object.keys(window.tracks).length == 0) {
            // console.log("deferring constructTrackIndicators until window.tracks is available");
            setTimeout(this.constructTrackIndicators.bind(this), 500);
            return;
        }
        let section = window.currentSection;
        let greens = document.getElementById("ch-player-tracks-green");
        let blues = document.getElementById("ch-player-tracks-blue");
        while (greens.firstChild) {
            greens.removeChild(greens.lastChild);
        }
        while (blues.firstChild) {
            blues.removeChild(blues.lastChild);
        }
        
        let that = this;
        for (let track in window.tracks[section][0]) {
            let el = document.createElement("span");
            el.classList = ["ch-player-track-indicator"];
            if (window.tracks[section][0][track]["preloaded"]==true) {
                el.classList.add("loaded");
            }
            if (window.tracks[section][0][track]["finished"]==true) {
                el.classList.add("finished");
            }
            if (track==this.anxiousIndex[2]) {
                el.classList.add("active");
                el.classList.add("animate__animated");
                el.classList.add("animate__flash");
            }
            el.addEventListener("click",function() { 
                if (that.relaxedTimeout) {
                    clearTimeout(that.relaxedTimeout);
                }
                if (that.anxiousTimeout) {
                    clearTimeout(that.anxiousTimeout);
                }
                // console.log("changing to" + [section, 0, track]);
                document.getElementById("ch-player").classList.remove("ch-green");
                document.getElementById("ch-player").classList.remove("ch-blue");
                document.getElementById("ch-player-captions").classList.add("faded");
                that.changeTracks.bind(that,[section,0,track],undefined)() } );
            greens.appendChild(el);
        }

        for (let track in window.tracks[section][1]) {
            let el = document.createElement("span");
            el.classList = ["ch-player-track-indicator"];
            if (window.tracks[section][1][track]["preloaded"]==true) {
                el.classList.add("loaded");
            }
            if (window.tracks[section][1][track]["finished"]==true) {
                el.classList.add("finished");
            }
            if (track==this.relaxedIndex[2] ) {
                el.classList.add("active");
                el.classList.add("animate__animated");
                el.classList.add("animate__flash");
            }
            el.addEventListener("click",function() {
                if (that.relaxedTimeout) {
                    clearTimeout(that.relaxedTimeout);
                }
                if (that.anxiousTimeout) {
                    clearTimeout(that.anxiousTimeout);
                }
                // console.log("changing to" + [section, 1, track]);
                document.getElementById("ch-player").classList.remove("ch-green");
                document.getElementById("ch-player").classList.remove("ch-blue");
                document.getElementById("ch-player-captions").classList.add("faded");
                that.changeTracks.bind(that,undefined,[section,1,track])() } );
            blues.appendChild(el);
        }
    }

    allDone(thing) {
        // console.log("We all done damb");
    }
}

export default Conductor;


