
export function translate(value, leftMin, leftMax, rightMin, rightMax) {
            
        let leftSpan = leftMax - leftMin;
        if (leftSpan==0) { return rightMin; }

        let rightSpan = rightMax - rightMin;

        if (value < leftMin) { 
            value = leftMin; } 
        if (value > leftMax) { 
            value = leftMax; } 
        // # Convert the left range into a 0-1 range (float)
        let valueScaled = (value - leftMin) / (leftSpan);
        // # Convert the 0-1 range into a value in the right range.
        return rightMin + (valueScaled * rightSpan);
    }
 