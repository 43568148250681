import Typewriter from 'typewriter-effect/dist/core';

function clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

function randomChoice(list) {
    // https://stackoverflow.com/questions/9071573/is-there-a-simple-way-to-make-a-random-selection-from-an-array-in-javascript-or    
    //array length = 5;
    var rand = Math.random();
    //rand = 0.9999;
    rand *= list.length; //(5)
    //rand = 4.9995
    rand = Math.floor(rand);
    return list[rand];
}

class Captioner { 

    constructor (captionElement, events) {
        this.captionElement = captionElement;
        this.lastPosition = 0;
        this.captions = {};
        this.lastTime = 0;
        this.typewriter = new Typewriter('#ch-player-captions', {
            autoStart: true,
            delay: 1,
          });
          this.typewriter.typeString(randomChoice(["Hello. ","Howdy. ","Gotta start somewhere. ","hiya! "]));
          this.typewriter.start({});
        this.interval = setInterval(this.scroller.bind(this),250);
    }
    
    start (captions) {
        document.getElementsByClassName('Typewriter__wrapper')[0].innerHTML = "";
        this.lastPosition = 0;
        this.captions = captions;
        this.lastTime = new Date().getTime();
    }

    scroller() {
        let newtime = new Date().getTime();
        if (newtime - this.lastTime < 500) { // ms
            let cap = document.querySelector(this.captionElement);
            cap.scrollTop = cap.scrollHeight; 
        }
    }

    reverseString(str) {
        return str.split("").reverse().join("");
    }
    
    searchStrings(from, to) {
        let returners = [];
        for (var key in this.captions) {
            if ((key >= from ) && (key < to)) {
                returners.push(this.captions[key]);
            }1
        }
        return returners;
    }
    submit (position) {
        this.lastTime = new Date().getTime();
        let changed = false;
        let concat = 1;
        if (position < this.lastPosition) {
            let caps = this.searchStrings(position, this.lastPosition).reverse();
            concat = caps.join(' ');
            for (var cap in caps) {
                this.typewriter.typeString(this.reverseString(caps[cap]) + " ");
                changed = true;
            }
        } else if (position > this.lastPosition) {
            let caps = this.searchStrings(this.lastPosition, position);
            concat = caps.join(' ');
            for (var cap in caps) {
                this.typewriter.typeString(caps[cap] + " ");
                changed = true;
            }
        }
        if (changed) {
            let delay = (100 / concat.length);
            this.typewriter.options.delay = delay;
            // console.log("setting delay to " + delay)
            this.typewriter.start();
        }
        this.lastPosition = position;
    } 
}

export default Captioner;


